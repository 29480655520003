import { Autocomplete, TextField } from "@mui/material";
import { Area } from "../types";
import AreaApi from "../api/Area.api";
import React from "react";

type Option = {
  label: string;
  value: Area;
};

export default function AreaMultiSelect({
  areas,
  setAreas,
  label
}: {
  areas?: Area[];
  setAreas: (arg: Area[]) => void;
  label: string;
}){
   const [value, setValue] = React.useState<Option[]>(
    areas
      ? areas.map(area => ({ value: area, label: area.name }))
      : []
  );
  const [inputValue, setInputValue] = React.useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);

  const { data, isFetching } = AreaApi.useList({ query: inputValue }); 

  const options: Option[] = data
    ? data.data.map(area => ({ label: area.name, value: area }))
    : [];

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      autoHighlight
      loading={isFetching}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      onChange={(event: any, newValue: Option[]) => {
        setValue(newValue);
        setAreas(newValue.map(option => option.value) as Area[]);
      }}
      filterOptions={(options, params) => {
        const filtered = options.filter(option => {
          if (areas) {
            return !areas.map(area => area.id).includes(option.value.id);
          } else {
            return true;
          }
        });

        return filtered;
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={params => (
        <TextField variant="standard" {...params} label={label} />
      )}
    />
  );
}