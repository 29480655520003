import { Dayjs } from "dayjs";
import { isEmpty, isNil, unionBy } from "lodash";
import { useMutation, useQuery } from "react-query";
import {
  ListResponseData,
  PlotBed,
  TrialVariety,
  TrialVarietyInput,
} from "../types";
import {
  handleDelete,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: (trialId?: number | string) => {
    return useQuery<TrialVariety[]>({
      queryKey: ["trial_varieties", trialId?.toString()],
      queryFn: () =>
        handleSimpleList({ url: `trials/${trialId}/trial_varieties` }),
      enabled: !!trialId,
    });
  },
  useListByVarietyIds: ({
    areaIds,
    commodityIds,
    culturalPractice,
    endDate,
    excludeYears,
    growerIds,
    page,
    regionIds,    
    shipperIds,
    startDate,      
    varietyIds,
  }: {
    areaIds: number[];
    commodityIds: number[];
    culturalPractice?: string;
    endDate: Dayjs | null;
    excludeYears: boolean;
    growerIds: number[];
    page?: number;
    regionIds: number[];
    shipperIds: number[];
    startDate: Dayjs | null;
    varietyIds: string[];    
  }) => {
    return useQuery<ListResponseData<TrialVariety>>({
      queryKey: [
        "trial_varieties_by_variety_ids",                      
        areaIds.join(","),
        commodityIds.join(","),        
        culturalPractice,
        endDate?.format("YYYY-MM-DD"),
        excludeYears,
        growerIds.join(","),
        page?.toString(),
        regionIds.join(","),
        shipperIds.join(","),
        startDate?.format("YYYY-MM-DD"),
        varietyIds.join(","),
      ],
      queryFn: () =>
        handleList({
          baseUrl: `trial_varieties/by_variety_ids`,
          params: [
            { key: "ids", value: varietyIds.join(",") },
            { key: "area_ids", value: areaIds.join(",") },
            { key: "commodity_ids", value: commodityIds.join(",") },
            { key: "cultural_practice", value: culturalPractice },
            { key: "grower_ids", value: growerIds.join(",") },
            { key: "end_date", value: endDate?.format("YYYY-MM-DD") },
            { key: "exclude_years", value: excludeYears },
            { key: "page", value: page },
            { key: "region_ids", value: regionIds.join(",") },
            { key: "shipper_ids", value: shipperIds.join(",") },
            { key: "start_date", value: startDate?.format("YYYY-MM-DD") },
          ],
        }),
      enabled: !isEmpty(varietyIds) && !isNil(page),
    });
  },
  useVarietyReports: ({
    varietyIds,
    page,
  }: {
    varietyIds: string[];
    page?: number;
  }) => {
    return useQuery<ListResponseData<TrialVariety>>({
      queryKey: [
        "trial_varieties_reports",
        varietyIds.sort().join(","),
        page?.toString(),
      ],
      queryFn: () =>
        handleList({
          baseUrl: `trial_varieties/reports`,
          params: [
            { key: "ids", value: varietyIds.join(",") },
            { key: "page", value: page },
          ],
        }),
      enabled: !isEmpty(varietyIds) && !isNil(page),
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (trialVarietyInput: TrialVarietyInput) =>
        handleSave<TrialVariety>({
          baseUrl: `trials/${trialVarietyInput.trialId}/trial_varieties`,
          input: {
            ...trialVarietyInput,
            trialId: trialVarietyInput.trialId,
          },
        }),
      onSuccess: async (updatedTrialVariety: TrialVariety) => {
        // queryClient.removeQueries({
        //   queryKey: ["trial_varieties", updatedTrialVariety.trialId.toString()],
        //   exact: true
        // })
        queryClient.setQueryData(
          ["trial_varieties", updatedTrialVariety.trialId.toString()],
          (oldData: TrialVariety[] | undefined) => {
            if (oldData) {
              return unionBy([updatedTrialVariety], oldData, "id");
            } else {
              return [updatedTrialVariety];
            }
          }
        );
        queryClient.setQueryData(
          ["plot_beds", "trial", updatedTrialVariety.trialId.toString()],
          (oldData: PlotBed[] | undefined) => {
            return oldData
              ? [...oldData, ...updatedTrialVariety.plotBeds]
              : updatedTrialVariety.plotBeds;
          }
        );
      },
    });
  },
  useDelete: () => {
    return useMutation({
      mutationFn: (trialVariety: TrialVariety) =>
        handleDelete<{ id: string; trialId: string }>({
          baseUrl: `trials/${trialVariety.trialId}/trial_varieties`,
          id: trialVariety.id,
        }),
      onSuccess: async ({ id, trialId }: { id: string; trialId: string }) => {
        queryClient.setQueryData(
          ["trial_varieties", trialId],
          (oldData: TrialVariety[] | undefined) => {
            return (
              oldData?.filter(
                trialVariety => trialVariety.id?.toString() !== id?.toString()
              ) || []
            );
          }
        );
        queryClient.setQueryData(
          ["plot_beds", "trial", trialId.toString()],
          (oldData: PlotBed[] | undefined) => {
            return (
              oldData?.filter(
                plotBed => plotBed.plotsId?.toString() !== id?.toString()
              ) || []
            );
          }
        );
        queryClient.setQueryData(
          ["plot_beds", "variety", trialId],
          (oldData: PlotBed[] | undefined) => {
            return (
              oldData?.filter(
                plotBed => plotBed.plotsId?.toString() !== id?.toString()
              ) || []
            );
          }
        );
      },
    });
  },
};
export default methods;
