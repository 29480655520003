import React from 'react'
import * as Sentry from "@sentry/react";
import {FieldVariety, PlotType, Trial, Variety } from "../../types";
import VarietySelect from "../VarietySelect";
import { Box, Modal, Chip, Typography } from "@mui/material";
import FieldVarietyApi from "../../api/FieldVariety.api";
import TrialVarietyApi from "../../api/TrialVariety.api";
import toast from "react-hot-toast";
import LabelIcon from "@mui/icons-material/Label";
import CheckIcon from "@mui/icons-material/CheckCircle";
import AttachTrialVarietyToFieldVariety from "./AttachTrialVarietyToFieldVariety";
import SelectedTypeVariety from "./SelectedTypeVariety";
import { isTypeVarietyComplete } from '../../utils'
import dayjs, { Dayjs } from 'dayjs'
import { useTrialContext } from '../../contexts/Trial.context'
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function EvaluationModal({ trial, plotType, plotRows, plotCols, wetDate }: {
  trial: Trial;
  plotType: PlotType;
  plotRows: number;
  plotCols: number;
  wetDate: Dayjs | null;
}){
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    selectedFieldVariety,
    setSelectedFieldVariety,
    selectedTrialVariety,
    setSelectedTrialVariety,
  } = useTrialContext();

  const { data: fieldVarieties } = FieldVarietyApi.useList(trial.id);
  const [variety, setVariety] = React.useState<Variety | undefined>(undefined);

  const { mutateAsync: createFieldVariety } = FieldVarietyApi.useSave();
  const { mutateAsync: deleteFieldVariety } = FieldVarietyApi.useDelete();

  const handleDeleteFieldVariety = (fieldVariety: FieldVariety) => async () => {
    try {
      await deleteFieldVariety(fieldVariety);
      toast.success("Successfully deleted.");
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error("Failed to delete image");
    }
  };

  const handleFieldVarietySelect = (fieldVariety: FieldVariety) => () => {
    setSelectedFieldVariety && setSelectedFieldVariety(fieldVariety);
  };

  const handleAttachFieldVariety = async (variety: Variety) => {
    try {
      setVariety(variety);
      if (variety) {
        await createFieldVariety({
          varietyId: variety.id,
          trialId: trial.id,
        });
        toast.success("Successfully attached Field Variety.");
      }
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      toast.error("Failed to attach Field Variety");
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? "100%" : "70%",
    height: isSmallScreen ? "100%" : '100%',
    overflow:'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: "1rem",
    disably: "block",
  }

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ flex: 1 }}>
          <VarietySelect
            setVariety={handleAttachFieldVariety}
            variety={variety}
            label={"Add Field Variety"}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          {/* show all the field varieties for the trial */}
          {fieldVarieties &&
            fieldVarieties
              .sort((a, b) => a.id - b.id)
              .map(fieldVariety => (
                <Chip
                  key={fieldVariety.id}
                  onClick={handleFieldVarietySelect(fieldVariety)}
                  variant={
                    selectedFieldVariety?.id === fieldVariety.id
                      ? "filled"
                      : "outlined"
                  }
                  label={
                    <Box sx={{display: "flex", flexDirection: "row", gap: "0.3rem", alignItems: "center", justifyContent: "center"}}>
                      <Typography variant="body1">{fieldVariety.variety.name}</Typography>
                      <Typography variant="caption">{dayjs(fieldVariety.latestUpdate).format("MMM D, YYYY")}</Typography>
                    </Box>
                  }
                  onDelete={handleDeleteFieldVariety(fieldVariety)}
                  avatar={isTypeVarietyComplete(fieldVariety) ? <CheckIcon /> : <LabelIcon />}
                />
              ))}
        </Box>
      </Box>
      <Box>
        {selectedFieldVariety && (
          <Modal
            open={!!selectedFieldVariety}
            onClose={() => setSelectedFieldVariety && setSelectedFieldVariety(undefined)}
            aria-labelledby="modal-field-variety-title"
            aria-describedby="modal-field-variety-description"
          >
            <Box sx={modalStyle}>
              <SelectedTypeVariety
                wetDate={wetDate}
                typeVariety={selectedFieldVariety}
                evaluableType={"field_variety"}
                useSave={FieldVarietyApi.useSave}
                handleClose={() => setSelectedFieldVariety && setSelectedFieldVariety(undefined)}
              >
                <>
                  <Typography variant={"h5"}>
                    {selectedFieldVariety.variety.name}
                  </Typography>
                  <Typography variant={"h5"}>Field Variety Evaluation</Typography>
                  <Box>
                    <AttachTrialVarietyToFieldVariety
                      fieldVariety={selectedFieldVariety}
                      plotType={plotType}
                      plotRows={plotRows}
                      plotCols={plotCols}
                    />
                  </Box>
                </>
              </SelectedTypeVariety>
            </Box>
          </Modal>
        )}
        {selectedTrialVariety && (
          <Modal
            open={!!selectedTrialVariety}
            onClose={() => setSelectedTrialVariety && setSelectedTrialVariety(undefined)}
            aria-labelledby="modal-trial-variety-title"
            aria-describedby="modal-trial-variety-description"
          >
            <Box sx={modalStyle}>
              <SelectedTypeVariety
                wetDate={wetDate}
                typeVariety={selectedTrialVariety}
                evaluableType={"trial_variety"}
                handleClose={() => setSelectedTrialVariety && setSelectedTrialVariety(undefined)}
                useSave={TrialVarietyApi.useSave}
              >
                <Typography variant={"h5"}>
                  {selectedTrialVariety.variety.name}
                </Typography>
                <Typography variant={"h5"}>Trial Variety Evaluation</Typography>
              </SelectedTypeVariety>
            </Box>
          </Modal>
        )}
      </Box>
    </>
  )
}


