import FilterListIcon from "@mui/icons-material/FilterList";

import DescriptionIcon from "@mui/icons-material/Description";
import PrintIcon from "@mui/icons-material/Print";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";
import toast from "react-hot-toast";
import ReportApi from "../../api/Report.api";
import { useReportTypeVarietiesContext } from "../../contexts/ReportTypeVarieties.context";
import { HttpError } from "../../types/http-error";
import ReportStatus from "../ReportStatus";

export default function TypeVarietyHeader({
  showFilters,
  setShowFilters,
}: {
  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    areas,    
    commodities,
    culturalPractice,
    endDate,
    excludeYears,
    finishedLoading,
    growers,
    regions,
    startDate,
    shippers,
    varietyIds,
  } = useReportTypeVarietiesContext();

  const [reportId, setReportId] = React.useState<number | undefined>(undefined);

  const { mutateAsync: mutatePdf } = ReportApi.useCreate({
    areaIds: areas ? areas.map(area => area.id) : [],    
    commodityIds: commodities ? commodities.map(commodity => commodity.id) : [],
    culturalPractice,
    endDate: endDate || null,
    excludeYears: excludeYears || false,
    growerIds: growers ? growers.map(grower => grower.id) : [],    
    regionIds: regions ? regions.map(region => region.id) : [],
    shipperIds: shippers ? shippers.map(shipper => shipper.id) : [],
    startDate: startDate || null,    
    varietyIds: varietyIds ? varietyIds : [],
  });

  const { mutateAsync: mutateExcel } = ReportApi.useCreateExcel({
    areaIds: areas ? areas.map(area => area.id) : [],    
    commodityIds: commodities ? commodities.map(commodity => commodity.id) : [],
    culturalPractice,
    endDate: endDate || null,
    excludeYears: excludeYears || false,
    growerIds: growers ? growers.map(grower => grower.id) : [],    
    regionIds: regions ? regions.map(region => region.id) : [],
    shipperIds: shippers ? shippers.map(shipper => shipper.id) : [],
    startDate: startDate || null,    
    varietyIds: varietyIds ? varietyIds : [],
  });

  const handleCreatePdf = async () => {
    try {
      const result = await mutatePdf();
      setReportId(result.id);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to print Report");
      }
    }
  };

  const handleCreateExcel = async () => {
    try {
      const result = await mutateExcel();
      console.log("handleCreateExcel", result);
      setReportId(result.id);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to print Report");
      }
    }
  };

  // const { mutateAsync: mutateEmail } = ReportApi.useSendEmail({
  //   reportType,
  //   fieldVarietyIds,
  //   trialVarietyIds,
  //   varietyIds,
  //   orderByWetDate: searchByWetDate || true,
  //   hideGrower: !displayGrower,
  //   hideShipper: !displayShipper,
  //   hideSupplier: !displaySupplier,
  //   hideRanch: !displayRanch,
  // });

  if (!finishedLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CircularProgress size="5rem" />
      </Box>
    );
  }

  // return (
  //   <Box>
  //     <Typography variant="h4">
  //       TrialVarieties.count {trialVarieties && trialVarieties.length}
  //     </Typography>
  //     <Typography variant="h4">
  //       FieldVarieties.count {fieldVarieties && fieldVarieties.length}
  //     </Typography>
  //   </Box>
  // );
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <>
          {reportId && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <ReportStatus reportId={reportId} />
            </Box>
          )}
          {/*<Box
            sx={{
              flexShrink: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              size={"large"}
              // onClick={() => setShowDrawer(true)}
            >
              <EmailIcon />
            </Button>
          </Box>*/}
          <Box
            sx={{
              flexShrink: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              size={"large"}
              onClick={handleCreateExcel}
            >
              <DescriptionIcon />
            </Button>
          </Box>
          <Box
            sx={{
              flexShrink: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant={"contained"}
              color={"primary"}
              size={"large"}
              onClick={handleCreatePdf}
            >
              <PrintIcon />
            </Button>
          </Box>
          <Box
            sx={{
              flexShrink: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant={"outlined"}
              color={"primary"}
              size={"large"}
              onClick={() => setShowFilters(true)}
            >
              <FilterListIcon />
            </Button>
          </Box>
        </>
      </Grid>
    </Grid>
  );
}
