import { Dayjs } from "dayjs";
import { isEmpty, isNil, unionBy } from "lodash";
import { useMutation, useQuery } from "react-query";
import { FieldVariety, FieldVarietyInput, ListResponseData } from "../types";
import {
  handleDelete,
  handleDetail,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: (trialId?: number | string) => {
    return useQuery<FieldVariety[]>({
      queryKey: ["field_varieties", trialId?.toString()],
      queryFn: () =>
        handleSimpleList({ url: `trials/${trialId}/field_varieties` }),
      enabled: !!trialId,
    });
  },
  useListByVarietyIds: ({
    areaIds,
    commodityIds,
    culturalPractice,
    endDate,
    excludeYears,
    growerIds,
    page,
    regionIds,    
    shipperIds,
    startDate,      
    varietyIds,
  }: {
    areaIds: number[];
    commodityIds: number[];
    culturalPractice?: string;
    endDate: Dayjs | null;
    excludeYears: boolean;
    growerIds: number[];
    page?: number;
    regionIds: number[];
    shipperIds: number[];
    startDate: Dayjs | null;
    varietyIds: string[];    
  }) => {
    return useQuery<ListResponseData<FieldVariety>>({
      queryKey: [
        "field_varieties_by_variety_ids",
        areaIds.join(","),
        commodityIds.join(","),     
        culturalPractice,   
        endDate?.format("YYYY-MM-DD"),
        excludeYears,
        growerIds.join(","),
        page?.toString(),
        regionIds.join(","),
        shipperIds.join(","),
        startDate?.format("YYYY-MM-DD"),
        varietyIds.join(","),
      ],
      queryFn: () =>
        handleList({
          baseUrl: `field_varieties/by_variety_ids`,
          params: [
            { key: "ids", value: varietyIds.join(",") },
            { key: "area_ids", value: areaIds.join(",") },
            { key: "commodity_ids", value: commodityIds.join(",") },
            { key: "cultural_practice", value: culturalPractice },
            { key: "grower_ids", value: growerIds.join(",") },
            { key: "end_date", value: endDate?.format("YYYY-MM-DD") },
            { key: "exclude_years", value: excludeYears },
            { key: "page", value: page },
            { key: "region_ids", value: regionIds.join(",") },
            { key: "shipper_ids", value: shipperIds.join(",") },
            { key: "start_date", value: startDate?.format("YYYY-MM-DD") },
          ],
        }),
      enabled: !isEmpty(varietyIds) && !isNil(page),
    });
  },
  useDetail: ({
    trialId,
    fieldVarietyId,
  }: {
    trialId?: number | string;
    fieldVarietyId?: number | string;
  }) => {
    return useQuery<FieldVariety>({
      queryKey: ["field_variety", fieldVarietyId?.toString()],
      queryFn: () =>
        handleDetail({
          baseUrl: `trials/${trialId}/field_varieties`,
          id: fieldVarietyId,
        }),
      enabled: !!fieldVarietyId,
    });
  },
  useSave: () => {
    return useMutation({
      mutationFn: (fieldVarietyInput: FieldVarietyInput) =>
        handleSave<FieldVariety>({
          baseUrl: `trials/${fieldVarietyInput.trialId}/field_varieties`,
          input: {
            ...fieldVarietyInput,
            trialId: fieldVarietyInput.trialId,
          },
        }),
      onSuccess: async (data: FieldVariety) => {
        queryClient.setQueryData(
          ["field_variety", data?.id?.toString()],
          (oldData: any) => {
            return data;
          }
        );
        queryClient.setQueryData(
          ["field_varieties", data.trialId.toString()],
          (oldData: FieldVariety[] | undefined) => {
            if (oldData) {
              return unionBy([data], oldData, "id");
            } else {
              return [data];
            }
          }
        );
      },
    });
  },
  useDelete: () => {
    return useMutation({
      mutationFn: (fieldVariety: FieldVariety) =>
        handleDelete<{ id: string; trialId: string }>({
          baseUrl: `trials/${fieldVariety.trialId}/field_varieties`,
          id: fieldVariety.id,
        }),
      onSuccess: async ({ id, trialId }: { id: string; trialId: string }) => {
        queryClient.setQueryData(
          ["field_varieties", trialId],
          (oldData: FieldVariety[] | undefined) => {
            return (
              oldData?.filter(
                fieldVariety => fieldVariety.id !== parseInt(id)
              ) || []
            );
          }
        );
      },
    });
  },
};

export default methods;
