import { Box, Button, LinearProgress, Link, Typography } from "@mui/material";
import React from "react";
import ReportApi from "../api/Report.api";

const intervalTime = 5000;
export default function ReportStatusComponent({
  reportId,
}: {
  reportId: number;
}) {
  const { data, refetch } = ReportApi.useStatus({ id: reportId });

  React.useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, intervalTime);
    return () => clearInterval(interval);
  }, [refetch]);

  if (data && data.status === "completed" && data.link) {
    // data.link, data.error, data.percentageLeft
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: "0.5rem",
        }}
      >
        <Link
          underline="always"
          href={data.link}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          <Button variant="text">Link to download file</Button>
        </Link>
      </Box>
    );
  } else if (data && data.status === "error") {
    console.error(data.error);
    return (
      <Box sx={{ flex: 1, py: "0.5rem" }}>
        <Typography variant="h4" gutterBottom>
          Error generating report, please contact your web administrator.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        py: "0.5rem",
        flexGrow: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {data && data?.percentageLeft !== 0 ? (
        <LinearProgress
          color={"primary"}
          variant="determinate"
          value={data.percentageLeft || 0}
        />
      ) : (
        <LinearProgress color={"primary"} value={0} />
      )}
    </Box>
  );
}
