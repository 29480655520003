import { Box, Button, Modal, Typography, ImageListItem, ImageListItemBar } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import React from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { EvaluableType } from "../../types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import toast from "react-hot-toast";
import Compressor from "compressorjs";
import { round } from 'lodash'
import { grey, green } from '@mui/material/colors';

import ImageApi from '../../api/Image.api'

import { DirectUpload } from 'activestorage';

const API_ROOT = process.env.REACT_APP_BASE_URL;

export default function ImageUpload({
  evaluableId,
  evaluableType,
  refetchImages,
}: {
  evaluableId: number;
  evaluableType: EvaluableType;
  refetchImages: () => any;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const [files, setFiles] = React.useState<File[]>([])
  const [uploadSuccess, setUploadSuccess] = React.useState<boolean[]>([])
  const [loadingFiles, setLoadingFiles] = React.useState<boolean[]>([])

  const { mutateAsync: uploadImage } = ImageApi.useSave({
    serverUrl: evaluableType === "field_variety" ?
      `field_varieties/${evaluableId}/image` :
      `trial_varieties/${evaluableId}/image`
  })

  const handleUploadImage = (index: number) => async () => {
    try {
      setLoadingFiles(loadArr => {
        loadArr[index] = true
        return loadArr
      })
      toast(`Compressing Image...`)
      const compressedFile = await handleCompressImage(index)
      if(compressedFile){
        toast(`Uploading Image...`)
        const uploadFile: Promise<{ signed_id: string }> = new Promise((resolve, reject) => {
          const directUpload = new DirectUpload(
            compressedFile,
            `${API_ROOT}/rails/active_storage/direct_uploads`, // This url is exposed by default in your app
          );
          directUpload.create((error, blob) => {
            if (error) {
              reject();
            } else {
              resolve({ signed_id: blob.signed_id });
            }
          });
        })

        const result = await uploadFile
        await uploadImage({ evaluableType, signedId: result['signed_id'] })

        setUploadSuccess(prevFiles => {
          prevFiles[index] = true
          return prevFiles
        })
        toast.success(`Successfully uploaded the image.`)
      }
    } catch(error){
      toast.error(`Failed to upload the image`)
      console.error(error)
    } finally {
       setLoadingFiles(loadArr => {
        loadArr[index] = false
        return loadArr
      })
    }
    const {data} = await refetchImages();
    toast.success(`Total number of images now is ${data?.length}`)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files as File[];
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleCompressImage = async (index: number):Promise<File> => {
    const file = files[index];
    const compressedBlob: Blob = await new Promise((resolve, reject) => {
      new Compressor(file, {
        // quality: 0.6, // Adjust the desired image quality (0.0 - 1.0)
        // maxWidth: 800, // Adjust the maximum width of the compressed image
        // maxHeight: 800, // Adjust the maximum height of the compressed image
        mimeType: "image/jpeg", // Specify the output image format
        retainExif: true,
        convertSize: 1000000, // defaults to 5MB, currently set to 1MB
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    });
    const newFile = new File([compressedBlob], file.name, { type: compressedBlob.type })
    setFiles((prevFiles: File[]) => {
      prevFiles[index] = newFile as File;
      return [...prevFiles]
    })
    return newFile as File;
  }

  return (
    <>
      <Button
        fullWidth
        variant={"contained"}
        color={"info"}
        onClick={() => setOpen(true)}
      >
        <PhotoCameraIcon fontSize="large" color="primary" />
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isSmallScreen ? "100%" : "70%",
          height: isSmallScreen ? "100%" : '100%',
          overflow:'scroll',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}>
          <Box>
            <Typography variant={"h6"}>Attach files to evaluation</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: grey[300],
              p: "1rem",
              borderStyle: "dashed",
              borderColor: "#000",
              borderSize: "2px"
            }}
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
          >
            <React.Fragment>
              <input
                type="file"
                hidden
                id="browse"
                onChange={handleFileChange}
                accept=".jpeg,.jpg,.png"
                multiple
              />
              <label htmlFor="browse" className="browse-btn">
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                  <Typography variant="h6">Drag & Drop your files</Typography>
                  <Typography variant="h6">or</Typography>
                  <Typography variant="h6">Browse</Typography>
                </Box>
              </label>
            </React.Fragment>
          </Box>

          {files.length > 0 && (
            <Box>
              {files.map((file, index) => (
                <ImageListItem key={index} sx={{ mb: "1rem", textAlign: 'center'}}>
                  <img
                    alt="Uploaded evaluation"
                    src={URL.createObjectURL(file)}
                    style={{ objectFit: "cover", maxHeight: "20rem" }}
                  />

                  { uploadSuccess[index] && <ImageListItemBar
                    position={"top"}
                    title="Successfully uploaded"
                    sx={{ backgroundColor: green[800] }}
                  /> }
                  { loadingFiles[index] && <ImageListItemBar
                    position={"top"}
                    title="Uploading..."
                  /> }
                  <ImageListItemBar
                    sx={{ px: "0.5rem"}}
                    title={`${file.name} ~ ${round(file.size * 0.000001)} MB`}
                    actionIcon={

                      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                        <LoadingButton
                          variant="contained"
                          color="info"
                          onClick={handleUploadImage(index)}
                          disabled={loadingFiles[index] || uploadSuccess[index]}
                          loading={loadingFiles[index]}
                        >
                          Upload
                        </LoadingButton>
                      </Box>
                    }
                  />
                </ImageListItem>
              ))}
            </Box>
          )}

          <Box>
            <Button
              fullWidth
              variant="contained"
              color="info"
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
