import { useQuery, useMutation } from "react-query";
import { handleSimpleList, handleBasicGet } from "./handler";
import {
  CulturalPractice,
  HarvestDateType,
  Param,
  StatisticRecord,
} from "../types";
import dayjs, { Dayjs } from "dayjs";

const methods = {
  useCommodity: () => {
    return useMutation({
      mutationFn: ({
        areaId,
        commodityId,
        culturalPractice,
        dateType,
        endDate,
        regionId,
        startDate,
        varietyId,
      }: {
        areaId?: number;
        commodityId: number;
        culturalPractice: CulturalPractice;
        dateType: HarvestDateType;
        endDate: Dayjs;
        regionId: number;
        startDate: Dayjs;
        varietyId?: number;
      }) => {
        const params: Param[] = [];
        params.push({
          key: "commodity_id",
          value: commodityId,
        });
        params.push({
          key: "date_type",
          value: dateType,
        });
        params.push({
          key: "cultural_practice",
          value: culturalPractice,
        });
        params.push({
          key: "end_date",
          value: endDate.format("MM/DD/YY"),
        });
        params.push({
          key: "start_date",
          value: startDate.format("MM/DD/YY"),
        });
        if (areaId) {
          params.push({
            key: "area_id",
            value: areaId,
          });
        }
        if (regionId) {
          params.push({
            key: "region_id",
            value: regionId,
          });
        }
        if (varietyId) {
          params.push({
            key: "variety_id",
            value: varietyId,
          });
        }
        return handleSimpleList<StatisticRecord>({
          url: "statistics/commodity",
          params,
        });
      },
    });
  },
  useEstHarvestDate: ({
    commodityId,
    culturalPractice,
    dateType,
    searchDate,
    extraDays
  }: {
    commodityId?: number | string,
    culturalPractice?: string,
    dateType: string,
    searchDate: Date,
    extraDays?: number
  }) => {
    const params: Param[] = [];
    if(commodityId){
      params.push({
        key: "commodity_id",
        value: commodityId
      })
    }
    if(culturalPractice){
      params.push({
        key: "cultural_practice",
        value: culturalPractice
      })
    }
    if(dateType){
      params.push({
        key: "date_type",
        value: dateType
      })
    }
    if(searchDate){
      params.push({
        key: "search_date",
        value: searchDate
      })
    }
    if(extraDays){
      params.push({
        key: "extra_days",
        value: extraDays
      })
    }
    return useQuery<{ tempEstimatedHarvestDate: string }>({
      queryKey: ["est_harvest_date", commodityId?.toString(), culturalPractice, dateType, searchDate?.toString(), extraDays?.toString()],
      queryFn: () => handleBasicGet({ url: "statistics/est_harvest_date", params }),
      enabled: !!commodityId && dayjs(searchDate).isValid()
    })
  }
};

export default methods;
