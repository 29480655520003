import React from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import NurseryApi from "../api/Nursery.api";
import SimpleListName from "../components/SimpleListName";
import IndexHeaderButton from "../ui/IndexHeaderButton";
import { Nursery } from "../types";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { HttpError } from "../types/http-error";
import { Box, Grid, Typography } from "@mui/material";

export default function NurseryList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { mutateAsync: nurseryCreate } = NurseryApi.useSave({});

  const handleCreate = async () => {
    try {
      const result = await nurseryCreate();
      navigate(`/nurseries/${result.id}`);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      if (error instanceof HttpError && error.message) {
        toast.error(error.message?.split(",").join("\n"));
      } else {
        toast.error("Failed to create Nursery");
      }
    }
  };

  let headers = [
    { key: "name", label: "Name", getValue: (obj: Nursery) => obj?.name },
    {
      key: "wetDate",
      label: "Wet Date",
      getValue: (obj: Nursery) =>
        obj?.wetDate ? dayjs(obj.wetDate).format("LL") : "",
    },
    {
      key: "estTransferDate",
      label: "Est. Transfer Date",
      getValue: (obj: Nursery) =>
        obj.estTransferDate
          ? dayjs(obj.estTransferDate).format("LL")
          : "",
    },

  ]

  if(!isSmallScreen){
    headers = [...headers.splice(0), {
      key: "commodity",
      label: "Commodity",
      getValue: (obj: Nursery) => obj?.commodity?.name,
    }, ...headers.splice(1, 2), {
      key: "state",
      label: "State",
      getValue: (obj: Nursery) => (obj?.active ? "Active" : "Inactive"),
    }, ...headers.splice(2)]
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem"}}>
      <Grid container spacing={2}>
        <Grid item xs={9} sm={8}>
          <Typography
            variant={isSmallScreen ? "h4" : "h2"}
            sx={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            Nurseries
          </Typography>
        </Grid>
        <Grid item xs={3} sm={4} sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end"}}>
          <IndexHeaderButton handleCreate={handleCreate} />
        </Grid>
      </Grid>
      <SimpleListName
        useList={NurseryApi.useInfiniteList}
        useDelete={NurseryApi.useDelete}
        baseUrl="nurseries"
        headers={headers}
      />
    </Box>
  );
}
