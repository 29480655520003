import { Box } from "@mui/material";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React from "react";
import { useSearchParams } from "react-router-dom";

import AreaApi from "../api/Area.api"
import CommodityApi from "../api/Commodity.api";
import CompanyApi from "../api/Company.api"
import RegionApi from "../api/Region.api";
import ScatterPlot from "../components/reports/ScatterPlot";
import TypeVarietyColumns from "../components/reports/TypeVarietyColumns";
import TypeVarietyFilters from "../components/reports/TypeVarietyFilters";
import TypeVarietyHeader from "../components/reports/TypeVarietyHeader";
import { ReportTypeVarietiesProvider } from "../contexts/ReportTypeVarieties.context";

export default function ReportsCompare() {
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();

  const varietyIdParams = searchParams.get("varieties");
  const varietyIds = varietyIdParams ? varietyIdParams.split(",") : [];
  const commodityIdParams = searchParams.get("commodities");
  const commodityIds = commodityIdParams ? commodityIdParams.split(",") : [];
  const regionIdParams = searchParams.get("regions");
  const regionIds = regionIdParams ? regionIdParams.split(",") : [];
  const startDateParams = searchParams.get("start_date");
  const startDate = startDateParams
    ? dayjs(startDateParams, "YYYY-MM-DD")
    : null;
  const endDateParams = searchParams.get("end_date");
  const endDate = endDateParams ? dayjs(endDateParams, "YYYY-MM-DD") : null;
  const excludeYearParams = searchParams.get("exclude_years");
  const excludeYears = excludeYearParams === "true" ? true : false;
  const culturalPracticeParams = searchParams.get("cultural_practice");
  const culturalPractice = ['conventional', 'organic'].includes(culturalPracticeParams || "") ? (culturalPracticeParams || "") : undefined;
  const shipperIdParams = searchParams.get("shippers")
  const shipperIds = shipperIdParams ? shipperIdParams.split(",") : []
  const growerIdParams = searchParams.get("growers")
  const growerIds = growerIdParams ? growerIdParams.split(",") : []
  const areaIdParams = searchParams.get("areas")
  const areaIds = areaIdParams ? areaIdParams.split(",") : []

  const { data: initialCommodities, isFetched: commoditiesIsFetched } =
    CommodityApi.useListByIds({
      ids: commodityIds,
    });

  const { data: initialRegions, isFetched: regionsIsFetched } =
    RegionApi.useListByIds({
      ids: regionIds,
    });

  const { data: initialGrowers, isFetched: growersIsFetched } = 
    CompanyApi.useListByIds({
      ids: growerIds,
      companyCategoryName: "grower"
    })

  const { data: initialShippers, isFetched: shippersIsFetched } = 
    CompanyApi.useListByIds({
      ids: shipperIds,
      companyCategoryName: "shipper"
    })

  const { data: intitialAreas, isFetched: areasIsFetched } = 
    AreaApi.useListByIds({
      ids: areaIds,
    })

  const [heightOfScatterPlot, setHeightOfScatterPlot] = React.useState(220);
  React.useEffect(() => {
    function handleResize() {
      if (window.innerHeight < 400) {
        setHeightOfScatterPlot(220);
      } else {
        const height = Math.round(window.innerHeight / 1.7);
        setHeightOfScatterPlot(height);
      }
    }
    window.addEventListener("resize", handleResize);
  });

  if (
    (!regionsIsFetched && !isEmpty(regionIds)) ||
    (!commoditiesIsFetched && !isEmpty(commodityIds)) ||
    (!areasIsFetched && !isEmpty(areaIds)) ||
    (!growersIsFetched && !isEmpty(growerIds)) ||
    (!shippersIsFetched && !isEmpty(shipperIds)) 
  ) {
    return <Box>Loading ....</Box>;
  }
  return (
    <ReportTypeVarietiesProvider
      varietyIds={varietyIds}
      initialAreas={intitialAreas}
      initialCommodities={initialCommodities}
      initialGrowers={initialGrowers}      
      initialRegions={initialRegions}
      initialShippers={initialShippers}
      initialStartDate={startDate}
      initialEndDate={endDate}
      initialExcludeYears={excludeYears}
      initialCulturalPractice={culturalPractice}
    >
      <>
        <TypeVarietyFilters
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <TypeVarietyHeader
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
        <Box sx={{ flex: 1, height: heightOfScatterPlot }}>
          <ScatterPlot />
        </Box>
        <Box sx={{ flex: 1 }}>
          <TypeVarietyColumns />
        </Box>
      </>
    </ReportTypeVarietiesProvider>
  );
}
