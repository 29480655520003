import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import dayjs from "dayjs";
import { useReportTypeVarietiesContext } from "../../contexts/ReportTypeVarieties.context";
import { ColumnType, FieldVariety, TrialVariety } from "../../types";
import { getAllColors, reportCreateLayers, reportTooltip } from "../../utils";

type Point = {
  x: string;
  y: number;
};

export default function ScatterPlot() {
  const { varietyById, combinedTypeVarieties } =
    useReportTypeVarietiesContext();

  const createPoint = (record: TrialVariety | FieldVariety) => {
    if (record.maturityDate && record.score) {
      const maturityDate = dayjs(record.maturityDate);
      return {
        x: maturityDate.format("M-D"),
        y: record.score,
      };
    }
    return null;
  };

  const seperateDataByVariety = () => {
    const seperatedVarieties: { [key: number]: Point[] } = {};
    combinedTypeVarieties &&
      combinedTypeVarieties.forEach((column: ColumnType) => {
        if (!seperatedVarieties[column.typeVariety.variety.id]) {
          seperatedVarieties[column.typeVariety.variety.id] = [];
        }
        const point = createPoint(column.typeVariety);
        if (point) {
          seperatedVarieties[column.typeVariety.variety.id].push(point);
        }
      });
    return seperatedVarieties;
  };

  const compileData = () => {
    const varietyData = seperateDataByVariety();
    return Object.keys(varietyData).map((varietyId: string) => {
      const trialVarietyPoints = varietyData[parseInt(varietyId)];
      const variety = varietyById && varietyById[parseInt(varietyId)];
      return {
        id: variety?.name || varietyId,
        data: trialVarietyPoints,
      };
    });
  };

  const data = compileData();
  const layers = reportCreateLayers(data);

  let marginHeight = 50;
  if (data.length > 2) {
    marginHeight = data.length * 25;
  }
  return (
    <>
      <ResponsiveScatterPlot
        data={data}
        // symbolSize={18}
        // indexBy="date"
        yScale={{
          type: "linear",
          min: 0,
          max: 10,
        }}
        xScale={{
          type: "time",
          format: "%m-%d",
          precision: "day",
        }}
        axisBottom={{
          tickRotation: -40,
          format: "%b %d",
          tickValues: "every month",
        }}
        margin={{
          top: marginHeight, //need to change this depending on number in compare
          right: 10,
          bottom: 45,
          left: 36,
        }}
        colors={getAllColors()}
        enableGridX={true}
        tooltip={reportTooltip}
        legends={[
          {
            anchor: "top-left",
            direction: "column",
            translateY: -1 * marginHeight, //need to change this depending on number in compare
            translateX: 0,
            itemWidth: 10,
            itemHeight: 20,
            // dataFrom: "keys",
            symbolSize: 12,
            symbolShape: "circle",
          },
        ]}
        layers={layers}
      />
    </>
  );
}
