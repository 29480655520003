import Grid from "@mui/material/Grid2";
import dayjs from "dayjs";
import { useReportTypeVarietiesContext } from "../../contexts/ReportTypeVarieties.context";
import TypeVarietyColumn from "./TypeVarietyColumn";

export default function TypeVarietyColumns() {
  const { combinedTypeVarieties, excludeYears } =
    useReportTypeVarietiesContext();

  const sortedColumns = (combinedTypeVarieties || []).sort((a, b) => {
    const convertDate = excludeYears
      ? (d: string) => dayjs(d).dayOfYear()
      : (d: string) => dayjs(d);
    const orderByWetDate = true;
    let dateA: any = null;
    let dateB: any = null;
    if (orderByWetDate) {
      const wetDateA = a.typeVariety?.trial?.wetDate;
      dateA = convertDate(wetDateA);
      const wetDateB = b.typeVariety?.trial?.wetDate;
      dateB = convertDate(wetDateB);
    } else {
      const maturityDateA = a.typeVariety?.maturityDate;
      const maturityDateB = b.typeVariety?.maturityDate;
      dateA = convertDate(maturityDateA);
      dateB = convertDate(maturityDateB);
    }
    if (dateA > dateB) {
      return 1;
    }
    if (dateA < dateB) {
      return -1;
    }
    return 0;
  });

  return (
    <Grid container spacing={2}>
      {sortedColumns &&
        sortedColumns.map(column => (
          <Grid key={column.typeVariety.id} size={{ xs: 12, sm: 6, md: 4 }}>
            <TypeVarietyColumn
              typeVariety={column.typeVariety}
              evaluableType={column.evaluableType}
            />
          </Grid>
        ))}
    </Grid>
  );
}
