import { EvaluableType, Label, Tag, MultiEvaluation } from "../../types";
import * as Sentry from "@sentry/react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import React from "react";
import MultiEvaluationApi from "../../api/MultiEvaluation.api";
import toast from "react-hot-toast";

export default function MultiEvaluationComponent({
  multiEvaluations,
  multiEvaluableType,
  multiEvaluableId,
  label,
}: {
  multiEvaluations: MultiEvaluation[];
  multiEvaluableType: EvaluableType;
  multiEvaluableId: number;
  label: Label;
}) {
  const [tags, setTags] = React.useState<Tag[]>(
    label.tags.filter(tag =>
      multiEvaluations
        .map(multiEvaluation => multiEvaluation.tagId)
        .includes(tag.id)
    )
  );

  const { mutateAsync: saveMultiEvaluation } = MultiEvaluationApi.useSave();

  const handleChange = async (event: SelectChangeEvent<number[]>) => {
    const newTagIds = event.target.value as number[];
    const newTags = label.tags.filter(tag => newTagIds.includes(tag.id));
    setTags(newTags);
    try {
      await saveMultiEvaluation({
        multiEvaluationInput: newTags.map(tag => ({
          labelId: label.id,
          tagId: tag.id,
        })),
        labelId: label.id,
        multiEvaluableId,
        multiEvaluableType,
      });
      toast.success("Successfully saved.");
    } catch (e) {
      Sentry.captureException(e);
      console.error(e);
      toast.error("Failed to save.");
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="select-multi-evaluation-label" htmlFor="select-multi-evaluation">{label.name}</InputLabel>
      <Select
        fullWidth
        labelId="select-multi-evaluation-label"
        id="select-multi-evaluation"
        label={label.name}
        multiple
        input={<OutlinedInput id="select-multiple-chip" label={label.name} />}
        value={tags.map(tag => tag.id)}
        onChange={handleChange}
        renderValue={(selected: number[]) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((tagId: number) => (
              <Chip
                key={tagId}
                label={label.tags.find(tag => tag.id === tagId)?.name}
              />
            ))}
          </Box>
        )}
      >
        {label.tags
          .sort((a, b) => a.location - b.location)
          .map((tag: Tag) => (
            <MenuItem value={tag.id} key={tag.id}>
              {tag.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
