import React from "react";
import * as Sentry from "@sentry/react";
import {
  Criterium,
  CriteriaMeasurement,
  Evaluation,
  Measurement,
  MeasurementEvaluation,
  Subcriterium,
  EvaluableType,
} from "../types";
import CriteriumApi from "../api/Criterium.api";
import CriteriaMeasurementApi from "../api/CriteriaMeasurement.api";
import EvaluationApi from "../api/Evaluation.api";
import MeasurementApi from "../api/Measurement.api";
import MeasurementEvaluationApi from "../api/MeasurementEvaluation.api";
import { SelectChangeEvent } from "@mui/material/Select";
import { isEmpty, isNil, round } from "lodash";
import toast from "react-hot-toast";

type Props = {
  commodityId: number;
  evaluableType: EvaluableType;
  evaluableId: number;
  children: JSX.Element;
};

interface IEvaluationContext {
  criteria: Criterium[];
  criteriaMeasurements: CriteriaMeasurement[];
  evaluations: Evaluation[];
  evaluationsIsLoading: boolean;
  measurements: Measurement[];
  measurementEvaluations: MeasurementEvaluation[];
  measurementEvaluationsIsLoading: boolean;
  measurementEvaluationsIsFetched: boolean;
  // subcriteria: { [keyof: number]: Subcriterium };
  handleEvaluationChange: (arg: {
    criterium: Criterium;
    evaluation?: Evaluation;
    evaluableType: EvaluableType;
    evaluableId: number;
  }) => (event: SelectChangeEvent<number>) => Promise<void>;
  getSelectedSubcriterium: (arg: {
    criteriumId: number;
  }) => Subcriterium | undefined;
  getAverageScoreByMeasurementId: (arg: { measurementId: number }) => number;
  setMeasurementScoreForAverage: (arg: {
    measurementId: number;
    index: number;
    value?: number;
  }) => void;
  getEvaluationScore: () => number;
}

const EvaluationContext = React.createContext<Partial<IEvaluationContext>>({
  criteria: [],
  criteriaMeasurements: [],
  evaluations: [],
  evaluationsIsLoading: false,
  measurements: [],
  measurementEvaluations: [],
  measurementEvaluationsIsLoading: false,
  measurementEvaluationsIsFetched: false
});

export function EvaluationProvider({
  commodityId,
  evaluableType,
  evaluableId,
  children,
}: Props) {
  const { data: criteria } = CriteriumApi.useList({
    commodityId: commodityId,
    numericalAutoSelect: false,
  });
  const { data: evaluations, isLoading: evaluationsIsLoading } =
    EvaluationApi.useList({
      evaluableType,
      evaluableId,
    });
  const { data: measurements } = MeasurementApi.useList(commodityId);
  const {
    data: measurementEvaluations,
    isLoading: measurementEvaluationsIsLoading,
    isFetched: measurementEvaluationsIsFetched,
    dataUpdatedAt: measurementEvaluationsDataUpdatedAt
  } = MeasurementEvaluationApi.useList({
    measurementEvaluableType: evaluableType,
    measurementEvaluableId: evaluableId,
  });

  const { data: criteriaMeasurements } =
    CriteriaMeasurementApi.useByCommodityList({
      commodityId,
    });

  const { mutateAsync: saveEvaluation } = EvaluationApi.useSave();

  const [measurementValuesByIndexLastUpdated, setMeasurementValuesByIndexLastUpdated] = React.useState<undefined | number>(undefined)
  // Measurment ID is key to hash of measurementEvaluations to average
  const [measurementValuesByIndex, setMeasurementValuesByIndex] =
    React.useState<{ [key: number]: { [key: number]: undefined | number } }>(
      {}
    );

  // [criteriumID] = selectedSubcriterium
  const [selectedSubcriteria, setSelectedSubcriteria] = React.useState<{
    [key: number]: Subcriterium | undefined;
  }>({});

  React.useEffect(() => {
    if (evaluations) {
      evaluations.forEach(evaluation => {
        setSelectedSubcriteria(obj => ({
          ...obj,
          [evaluation.criteriumId]: evaluation.subcriterium,
        }));
      });
    }
  }, [evaluations]);

  const getEvaluationScore = () => {
    return evaluations ? round(evaluations.map(evaluation => evaluation.weightedScore).reduce((accumulator, currentValue) => {
      return accumulator + currentValue
    }, 0), 2) : 0;
  }

  const getAverageScoreByMeasurementId: (arg: {
    measurementId: number;
  }) => number =
    ({ measurementId }) => {
      const valuesByIndex = measurementValuesByIndex[measurementId] || {};
      const averageScoreArr: number[] = Object.values(valuesByIndex).filter(
        val => !isNil(val)
      ) as number[];
      const totalScore: number =
        averageScoreArr.reduce(
          (accumulator: number, currentValue: number) =>
            accumulator + currentValue,
          0
        ) || 0;
      const averageScore =
        averageScoreArr.length === 0 ? 0 : round(totalScore / averageScoreArr.length, 2)
      return averageScore;
    }

  const updateSelectedSubcriterium = React.useCallback(
    async ({
      subcriteriumToSelect,
      evaluations,
      criterium,
      evaluableType,
      evaluableId,
    }: {
      subcriteriumToSelect?: Subcriterium;
      evaluations?: Evaluation[];
      criterium: Criterium;
      evaluableType: EvaluableType;
      evaluableId: number;
    }) => {
      const associatedEvaluation: Evaluation | undefined =
        evaluations &&
        evaluations.find(
          evaluation =>
            evaluation.commodityId === criterium.commodityId &&
            evaluation.criteriumId === criterium.id
        );
      try {
        setSelectedSubcriteria(obj => ({
          ...obj,
          [criterium.id]: subcriteriumToSelect,
        }));
        saveEvaluation({
          evaluationInput: {
            id: associatedEvaluation?.id,
            subcriteriumId: subcriteriumToSelect?.id,
            criteriumId: criterium.id,
            commodityId: criterium.commodityId,
          },
          evaluableType,
          evaluableId,
        });
        toast.success("Successfully saved.");
      } catch (error) {
        toast.error("Failed to delete.");
        Sentry.captureException(error);
        console.error(error);
      }
    },
    [saveEvaluation]
  );

  const setMeasurementScoreForAverage = React.useCallback(
    ({
      measurementId,
      index,
      value,
    }: {
      measurementId: number;
      index: number;
      value?: number;
    }) => {
      let measurementValuesByIndexCopy = { ...measurementValuesByIndex };
      const valuesByIndex = measurementValuesByIndexCopy[measurementId] || {};
      if (isNil(value)) {
        delete valuesByIndex[index];
      } else {
        valuesByIndex[index] = value;
      }
      measurementValuesByIndexCopy = { ...measurementValuesByIndexCopy, [measurementId]: { ...valuesByIndex } };

      setMeasurementValuesByIndex(measureValuesByIndex => {
        const valuesByIndex = measureValuesByIndex[measurementId] || {};
        if (isNil(value)) {
          delete valuesByIndex[index];
        } else {
          valuesByIndex[index] = value;
        }
        return { ...measureValuesByIndex, [measurementId]: { ...valuesByIndex } };
      });

      const measurement =
        measurements && measurements.find(m => m.id === measurementId);
      if (
        measurement &&
        criteriaMeasurements &&
        !isEmpty(criteriaMeasurements)
      ) {
        // 1. measurement find all the criteria it is attached too
        const associatedCriteriaMeasurements: CriteriaMeasurement[] =
          criteriaMeasurements.filter(
            (criteriaMeasurement: CriteriaMeasurement) => {
              return (
                criteriaMeasurement.measurementId.toString() ===
                measurement.id.toString()
              );
            }
          );
        const criteriaMeasurementsGroupedByCriterium: {
          [key: string]: CriteriaMeasurement[];
        } = {};
        associatedCriteriaMeasurements.forEach(
          (criteriaMeasurement: CriteriaMeasurement) => {
            if (
              criteriaMeasurementsGroupedByCriterium[
                criteriaMeasurement.criteriumId.toString()
              ] === undefined
            ) {
              criteriaMeasurementsGroupedByCriterium[
                criteriaMeasurement.criteriumId.toString()
              ] = [];
            }
          }
        );
        // 2. then find all the associated measurements for each criteria to do math on
        Object.keys(criteriaMeasurementsGroupedByCriterium).forEach(
          (criteriumId: string) => {
            criteriaMeasurementsGroupedByCriterium[criteriumId.toString()] =
              criteriaMeasurements.filter(
                (criteriaMeasurement: CriteriaMeasurement) => {
                  return (
                    criteriaMeasurement.criteriumId.toString() === criteriumId
                  );
                }
              );
          }
        );

        // 3. then apply math functions to group
        Object.keys(criteriaMeasurementsGroupedByCriterium).forEach(
          (criteriumId: string) => {
            let numerator = 0;
            let denominator = 0;
            let containsDenominator: boolean = false;
            criteriaMeasurementsGroupedByCriterium[criteriumId].forEach(
              (criteriaMeasurement: CriteriaMeasurement) => {
                // const averageScore = getAverageScoreByMeasurementId({
                //   measurementId: criteriaMeasurement.measurementId,
                // });
                const measurementId = criteriaMeasurement.measurementId;
                const valuesByIndex = measurementValuesByIndexCopy[measurementId] || {};
                const averageScoreArr: number[] = Object.values(valuesByIndex).filter(
                  val => !isNil(val)
                ) as number[];
                const totalScore: number =
                  averageScoreArr.reduce(
                    (accumulator: number, currentValue: number) =>
                      accumulator + currentValue,
                    0
                  ) || 0;
                const averageScore =
                  averageScoreArr.length === 0 ? 0 : round(totalScore / averageScoreArr.length, 2)

                if (criteriaMeasurement.math === "multiply") {
                  if(numerator === 0){
                    numerator += averageScore;
                  } else if(averageScore > 0) {
                    numerator = numerator * averageScore;
                  }
                } else {
                  if(denominator === 0){
                    denominator += averageScore;
                  } else if(averageScore > 0) {
                    denominator = denominator * averageScore;
                  }
                  containsDenominator = true;
                }
              }
            );
            let score: number | undefined = undefined;
            if (containsDenominator && denominator !== 0) {
              score = round(numerator / denominator, 2);
            } else if(!containsDenominator){
              score = round(numerator, 2);
            }
            // 4. then update selected subcriteriums
            const criterium =
              criteria &&
              criteria.find(
                (criterium: Criterium) =>
                  criteriumId === criterium.id.toString()
              );
            if (!isNil(criterium) && !isNil(score) && criterium?.numericalAutoSelect) {
              let subcriteriumToSelect: Subcriterium | undefined;
              criterium.subcriteria.sort((a,b) => a.location - b.location).forEach((subcriterium: Subcriterium) => {
                if (
                  !(
                    isNil(subcriterium.numericalMax) &&
                    isNil(subcriterium.numericalMin)
                  ) &&
                  (isNil(subcriterium.numericalMax) ||
                    (score !== undefined && round(subcriterium.numericalMax, 2) >= score)) &&
                  (isNil(subcriterium.numericalMin) ||
                    (score !== undefined && round(subcriterium.numericalMin, 2) <= score))
                ) {
                  subcriteriumToSelect = subcriterium;
                }
              });
              if (
                !isNil(subcriteriumToSelect) &&
                subcriteriumToSelect.id !==
                  selectedSubcriteria[criterium.id]?.id
              ) {
                updateSelectedSubcriterium({
                  subcriteriumToSelect,
                  evaluations,
                  criterium,
                  evaluableType,
                  evaluableId,
                });
              } else if(isNil(subcriteriumToSelect) && !isNil(selectedSubcriteria[criterium.id])) {
                updateSelectedSubcriterium({
                  subcriteriumToSelect: undefined,
                  evaluations,
                  criterium,
                  evaluableType,
                  evaluableId,
                });
              }
            }
          }
        );
      }
    },
    [
      criteria,
      criteriaMeasurements,
      evaluations,
      evaluableId,
      evaluableType,
      measurements,
      measurementValuesByIndex,
      updateSelectedSubcriterium,
      selectedSubcriteria,
      setMeasurementValuesByIndex
    ]
  );

  React.useEffect(() => {
    if(measurementEvaluations && measurementEvaluationsIsFetched && measurementValuesByIndexLastUpdated === undefined){
      measurementEvaluations.forEach(measurementEval => {
        setMeasurementValuesByIndex(measureValuesByIndex => {
          const valuesByIndex = measureValuesByIndex[measurementEval.measurementId] || {};
          if(isNil(valuesByIndex[measurementEval.measurementIndex])){
            valuesByIndex[measurementEval.measurementIndex] = measurementEval.value;
          }
          return { ...measureValuesByIndex, [measurementEval.measurementId]: { ...valuesByIndex } };
        });
      })
      setMeasurementValuesByIndexLastUpdated(measurementEvaluationsDataUpdatedAt)
    }
  }, [
    measurementEvaluations,
    setMeasurementValuesByIndex,
    measurementValuesByIndexLastUpdated,
    setMeasurementValuesByIndexLastUpdated,
    measurementEvaluationsDataUpdatedAt,
    measurementEvaluationsIsFetched
  ])

  const handleEvaluationChange = React.useCallback(
    ({
        criterium,
        evaluation,
        evaluableType,
        evaluableId,
      }: {
        criterium: Criterium;
        evaluation?: Evaluation;
        evaluableType: EvaluableType;
        evaluableId: number;
      }) =>
      async (event: SelectChangeEvent<number>) => {
        const subcriteriumId = event.target.value as number;
        const selectedSubcriterium = criterium.subcriteria.find(
          subcriterium => subcriterium.id === subcriteriumId
        );
        setSelectedSubcriteria(obj => ({
          ...obj,
          [criterium.id]: selectedSubcriterium,
        }));
        try {
          await saveEvaluation({
            evaluationInput: {
              id: evaluation?.id,
              subcriteriumId: selectedSubcriterium?.id,
              criteriumId: criterium.id,
              commodityId: criterium.commodityId,
            },
            evaluableType,
            evaluableId,
          });
          toast.success(`Successfully saved ${criterium.name}.`);
        } catch (e) {
          toast.error("Failed to save.");
          Sentry.captureException(e);
          console.error(e);
        }
      },
    [setSelectedSubcriteria, saveEvaluation]
  );

  const getSelectedSubcriterium = ({
    criteriumId,
  }: {
    criteriumId: number;
  }) => {
    return selectedSubcriteria[criteriumId];
  };

  return (
    <EvaluationContext.Provider
      value={{
        criteria,
        criteriaMeasurements,
        evaluations,
        evaluationsIsLoading,
        measurements,
        measurementEvaluations,
        measurementEvaluationsIsLoading,
        measurementEvaluationsIsFetched,
        handleEvaluationChange,
        getSelectedSubcriterium,
        setMeasurementScoreForAverage,
        getAverageScoreByMeasurementId,
        getEvaluationScore
      }}
    >
      {children}
    </EvaluationContext.Provider>
  );
}

export function useEvaluationContext() {
  return React.useContext(EvaluationContext);
}
