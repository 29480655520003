import { Dayjs } from "dayjs";
import { useMutation, useQuery } from "react-query";
import { ReportStatus, ReportType } from "../types";
import { handleBasicGet, handleBasicPost } from "./handler";

const methods = {
  useStatus: ({ id }: { id?: number }) => {
    return useQuery({
      queryKey: ["report", id?.toString()],
      queryFn: () => handleBasicGet<ReportStatus>({ url: `reports/${id}` }),
      enabled: !!id,
      cacheTime: 0,
    });
  },
  useCreate: ({
    areaIds,
    commodityIds,
    culturalPractice,
    endDate,
    excludeYears,
    growerIds,
    regionIds,    
    shipperIds,
    startDate,      
    varietyIds,
  }: {
    areaIds: number[];
    commodityIds: number[];
    culturalPractice?: string;
    endDate: Dayjs | null;
    excludeYears: boolean;
    growerIds: number[];
    regionIds: number[];
    shipperIds: number[];
    startDate: Dayjs | null;
    varietyIds: string[];   
  }) => {
    return useMutation({
      mutationFn: () =>
        handleBasicPost({
          url: "reports",
          input: {
            varietyIds: varietyIds,
            areaIds,
            commodityIds,
            culturalPractice,
            growerIds,
            endDate: endDate?.format("YYYY-MM-DD"),
            excludeYears: excludeYears,            
            regionIds,
            shipperIds,
            startDate: startDate?.format("YYYY-MM-DD"),                    
          },
        }),
      retry: 1,
    });
  },
  useCreateExcel: ({
    areaIds,
    commodityIds,
    culturalPractice,
    endDate,
    excludeYears,
    growerIds,
    regionIds,    
    shipperIds,
    startDate,      
    varietyIds,
  }: {
    areaIds: number[];
    commodityIds: number[];
    culturalPractice?: string;
    endDate: Dayjs | null;
    excludeYears: boolean;
    growerIds: number[];
    regionIds: number[];
    shipperIds: number[];
    startDate: Dayjs | null;
    varietyIds: string[];   
  }) => {
    return useMutation({
      mutationFn: () =>
        handleBasicPost({
          url: "reports/excel",
          input: {
            varietyIds: varietyIds,
            areaIds,
            commodityIds,
            culturalPractice,
            growerIds,
            endDate: endDate?.format("YYYY-MM-DD"),
            excludeYears: excludeYears,            
            regionIds,
            shipperIds,
            startDate: startDate?.format("YYYY-MM-DD"),             
          },
        }),
      retry: 1,
    });
  },
  useSendEmail: ({
    reportType,
    fieldVarietyIds,
    trialVarietyIds,
    varietyIds,
    hideGrower,
    hideShipper,
    hideSupplier,
    hideRanch,
    orderByWetDate,
  }: {
    reportType: ReportType;
    fieldVarietyIds: number[];
    trialVarietyIds: number[];
    varietyIds: string[];
    hideGrower: boolean;
    hideShipper: boolean;
    hideSupplier: boolean;
    hideRanch: boolean;
    orderByWetDate: boolean;
  }) => {
    return useMutation({
      mutationFn: (peopleIds: number[]) =>
        handleBasicPost({
          url: "reports/email",
          input: {
            reportType,
            fieldVarietyIds,
            trialVarietyIds,
            varietyIds,
            peopleIds,
            hideList: {
              grower: hideGrower,
              shipper: hideShipper,
              supplier: hideSupplier,
              ranch: hideRanch,
            },
            extra: {
              orderByWetDate,
            },
          },
        }),
      retry: 1,
    });
  },
};
export default methods;
