import {
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Divider,
  InputLabel,  
  MenuItem
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";

import { useReportTypeVarietiesContext } from "../../contexts/ReportTypeVarieties.context";
import CommodityMultiSelect from "../CommodityMultiSelect";
import RegionMultiSelect from "../RegionMultiSelect";
import CompanyMultiSelect from "../CompanyMultiSelect";
import AreaMultiSelect from "../AreaMultiSelect";

export default function TypeVarietyFilters({
  showFilters,
  setShowFilters,
}: {
  showFilters: boolean;
  setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    areas,
    setAreas,
    growers,
    setGrowers,
    shippers,
    setShippers,
    commodities,
    setCommodities,
    regions,
    setRegions,
    excludeYears,
    setExcludeYears,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    culturalPractice,
    setCulturalPractice,
  } = useReportTypeVarietiesContext();

  if (
    !areas ||
    !commodities ||
    !growers ||
    !regions ||
    !shippers ||    
    !setAreas || 
    !setCommodities ||
    !setEndDate || 
    !setExcludeYears ||
    !setGrowers ||
    !setRegions ||            
    !setStartDate ||    
    !setShippers ||
    !setCulturalPractice  
  ) {
    return <></>;
  }
  return (
    <Drawer
      open={showFilters}
      anchor="top"
      onClose={() => setShowFilters(false)}
    >
      <FormControl sx={{ p: "1rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <RegionMultiSelect
              regions={regions}
              setRegions={setRegions}
              label={"Regions"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommodityMultiSelect
              commodities={commodities}
              setCommodities={setCommodities}
              label={"Commodities"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={"Start Date"}
              sx={{ width: "100%" }}
              value={startDate}
              onChange={newValue => setStartDate && setStartDate(newValue)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label={"End Date"}
              sx={{ width: "100%" }}
              value={endDate}
              onChange={newValue => setEndDate && setEndDate(newValue)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormGroup>
            <FormControlLabel
              label="Exclude Years"
              control={
                <Switch
                  inputProps={{ "aria-label": "controlled" }}
                  checked={excludeYears}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setExcludeYears && setExcludeYears(event.target.checked);
                  }}
                />
              }
            />
          </FormGroup>
        </Grid>       
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CompanyMultiSelect
              companies={growers}
              setCompanies={setGrowers}
              label={"Growers"}
              companyCategoryName={"grower"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompanyMultiSelect
              companies={shippers}
              setCompanies={setShippers}
              label={"Shippers"}
              companyCategoryName={"shipper"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AreaMultiSelect
              areas={areas}
              setAreas={setAreas}
              label={"Areas"}              
            />
          </Grid>
           <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="cultural-practice-label">Cultural Practice</InputLabel>
            <Select
              labelId="cultural-practice-label"
              id="cultural-practice-select"
              value={culturalPractice}
              label="Cultural Practice"
              onChange={(event: SelectChangeEvent) => {
                setCulturalPractice(event.target.value as string);
              }}
            >
              <MenuItem value={undefined}>None</MenuItem>
              <MenuItem value={"conventional"}>Conventional</MenuItem>
              <MenuItem value={"organic"}>Organic</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        </Grid>
      </FormControl>
    </Drawer>
  );
}
