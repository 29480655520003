import { isEmpty } from "lodash";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";

import {
  ListResponseData,
  MetaPagination,
  Param,
  Region,
  RegionInput,
} from "../types";
import {
  handleDelete,
  handleDetail,
  handleInfiniteList,
  handleList,
  handleSave,
  handleSimpleList,
} from "./handler";
import queryClient from "./queryClient";

const methods = {
  useList: ({ query, page }: { query?: string; page?: number }) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    if (page) {
      params.push({
        key: "page",
        value: page,
      });
    }
    return useQuery<ListResponseData<Region>>({
      queryKey: ["regions", query?.toString(), page?.toString()],
      queryFn: () => handleList<Region>({ baseUrl: "regions", params }),
    });
  },
  useInfiniteList: (query?: string) => {
    const params: Param[] = [];
    if (query) {
      params.push({
        key: "search",
        value: query,
      });
    }
    return useInfiniteQuery({
      queryKey: ["regions"],
      queryFn: handleInfiniteList<Region>({ baseUrl: "regions", params }),
      keepPreviousData: false,
      getNextPageParam: (lastPage, pages) => lastPage.meta.nextPage,
      getPreviousPageParam: (firstPage, pages) => firstPage.meta.prevPage,
    });
  },
  useListByIds: ({ ids }: { ids: string[] }) => {
    const params: Param[] = [
      {
        key: "ids",
        value: ids,
      },
    ];
    return useQuery<Region[]>({
      enabled: !isEmpty(ids),
      queryKey: [
        "regions_by_ids",
        ids
          .sort()
          .map(id => id)
          .join(","),
      ],
      queryFn: () =>
        handleSimpleList<Region>({ url: "regions/by_ids", params }),
    });
  },
  useDetail: (id?: number | string) => {
    return useQuery({
      queryKey: ["region", id?.toString()],
      queryFn: () => handleDetail<Region>({ id, baseUrl: "regions" }),
      enabled: !!id,
    });
  },
  useSave: (input: RegionInput) => {
    return useMutation<Region>({
      mutationFn: () => handleSave({ baseUrl: "regions", input }),
      retry: 1,
      onSuccess: async data => {
        await queryClient.refetchQueries(["regions"]);
        queryClient.setQueryData(
          ["region", data?.id?.toString()],
          (oldData: any) => {
            return data;
          }
        );
      },
    });
  },
  useDelete: (id: number | string) => {
    return useMutation<{ id: number }>({
      mutationFn: () => handleDelete({ baseUrl: "regions", id }),
      onSuccess: async ({ id }: { id: number }) => {
        queryClient.setQueryData(["regions"], (oldData: any) => {
          const newPages = oldData.pages.map(
            (group: { data: Region[]; meta: MetaPagination }) => ({
              data: group.data.filter(
                region => region.id?.toString() !== id?.toString()
              ),
              meta: group.meta,
            })
          );
          return { pages: newPages };
        });
      },
    });
  },
};
export default methods;
