import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Hit } from "algoliasearch";
import { isEmpty } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { useInfiniteHits } from "react-instantsearch";
import { ReportChecked, TypeVarietyAlgolia } from "../../types";

export default function ReportsList(props: any) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { items, isLastPage, showMore } = useInfiniteHits(props);

  const sentinelRef = React.useRef(null);

  React.useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isLastPage) {
            // Load more hits
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  const { checked, toggleChecked, checkAll } = props;
  return (
    <Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Variety
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Commodity
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                Regions
              </Typography>
            </TableCell>
            <TableCell>
              <Checkbox
                // disabled={items.length > 5}
                checked={checked.length === items.length}
                inputProps={{
                  "aria-label": "Max 5 Varieties",
                }}
                onClick={() => {
                  // if (items.length > 5) {
                  //   return toast.error(
                  //     "Max 5 varieties can be selected at a time."
                  //   );
                  // }
                  const objectIds = checked.map(
                    ({ typeVarietyId }: ReportChecked) => typeVarietyId
                  );
                  const missingSome = items.some(
                    (item: any) => objectIds.indexOf(item.objectID) === -1
                  );
                  if (checked.length === items.length && !missingSome) {
                    checkAll([]);
                  } else {
                    checkAll(
                      items.map((item: any) => ({
                        typeVarietyId: item.objectID,
                        varietyId:
                          item.variety && item.variety.id
                            ? item.variety.id.toString()
                            : undefined,
                        varietyName: item?.variety?.name,
                        commodityName: item?.commodity?.name,
                        regionName: item?.region?.name,
                      }))
                    );
                  }
                }}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {checked.map((reportChecked: ReportChecked) => (
            <ReportCheckedRow
              key={reportChecked.varietyId}
              reportChecked={reportChecked}
              toggleChecked={toggleChecked}
            />
          ))}
          {items
            .filter(
              (row: any) =>
                checked
                  .map(({ typeVarietyId }: ReportChecked) => typeVarietyId)
                  .indexOf(row.objectID) === -1
            )
            .map((row: any) => (
              <AlgoliaVarietyRow
                checked={checked}
                toggleChecked={toggleChecked}
                row={row}
                key={row.objectID}
              />
            ))}
        </TableBody>
      </Table>
      <div ref={sentinelRef} aria-hidden="true" />
    </Box>
  );
}

function ReportCheckedRow({
  reportChecked,
  toggleChecked,
}: {
  reportChecked: ReportChecked;
  toggleChecked: (arg: ReportChecked) => void;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState<boolean>(false);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpen(o => !o);
  };
  return (
    <>
      <TableRow
        key={`checked-${reportChecked.typeVarietyId}`}
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        {isSmallScreen && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleToggle}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">{reportChecked.varietyName}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{reportChecked.commodityName}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">{reportChecked.regionName}</Typography>
        </TableCell>
        <TableCell>
          <Checkbox
            checked={true}
            onClick={() => toggleChecked(reportChecked)}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

function AlgoliaVarietyRow({
  checked,
  toggleChecked,
  row,
}: {
  checked: ReportChecked[];
  toggleChecked: (arg: ReportChecked) => void;
  row: Hit<TypeVarietyAlgolia>;
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState<boolean>(false);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpen(o => !o);
  };
  return (
    <>
      <TableRow key={row.objectID} sx={{ "& > *": { borderBottom: "unset" } }}>
        {isSmallScreen && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleToggle}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        <TableCell>
          <Typography variant="body2">{row.variety?.name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {!isEmpty(row?.commodity) ? row.commodity?.name : null}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2">
            {row?.region ? row.region?.name : null}
          </Typography>
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              checked
                .map(
                  ({ typeVarietyId, varietyId }: ReportChecked) => typeVarietyId
                )
                .indexOf(row.objectID) !== -1
            }
            onClick={() =>
              toggleChecked({
                typeVarietyId: row.objectID,
                varietyId:
                  row.variety && row.variety.id
                    ? row.variety.id.toString()
                    : undefined,
                varietyName: row?.variety?.name,
                commodityName: row?.commodity?.name,
                regionName: row?.region?.name,
              })
            }
          />
        </TableCell>
      </TableRow>
    </>
  );
}
