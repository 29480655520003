import { Box, Button, Grid, Paper } from "@mui/material";
import React from 'react'
import { useCommodityContext } from "../../contexts/Commodity.context";
import LabelSelectList from "./LabelSelectList";
import CommodityLabelForm from "./CommodityLabelForm";

export default function Labels(){
  const { commodity, labels } = useCommodityContext();

  const [addLabel, setAddLabel] = React.useState<boolean>(false)

  return (
    <Box>
      <Box sx={{ flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end", py: "1rem" }}>
        <Box sx={{flexShrink: 1}}>
          <Button
            color={"primary"}
            variant="outlined"
            onClick={() => setAddLabel(l => !l)}
          >
            {addLabel ? "Hide Diseases" : "Add Disease"}
          </Button>
        </Box>
      </Box>
      { addLabel && commodity?.id && (
        <LabelSelectList
          commodityId={commodity?.id}
          handleClose={() => setAddLabel(false)}
          attachedLabels={labels}
        />
      )}
      <Grid container spacing={2}>
        {labels &&
          commodity &&
          labels.map(label => (
            <Grid item xs={12} sm={6} md={4} key={label.id}>
              <Paper sx={{ p: "1rem", height: "100%" }}>
                <CommodityLabelForm commodity={commodity} label={label} />
              </Paper>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}