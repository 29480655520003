import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Typography } from "@mui/material";
import { liteClient } from "algoliasearch/lite";
import { Dayjs } from "dayjs";
import { isEmpty, remove } from "lodash";
import React from "react";
import toast from "react-hot-toast";
import { InstantSearch } from "react-instantsearch";
import { useNavigate } from "react-router-dom";
import ReportsList from "../components/algolia/ReportsList";
import ReportsSearch from "../components/algolia/ReportsSearch";
import { Commodity, Region, ReportChecked } from "../types";

const searchClient = liteClient(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID || "",
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY || ""
);

export default function Reports() {
  const navigate = useNavigate();
  const [checked, setChecked] = React.useState<ReportChecked[]>([]);
  const [regions, setRegions] = React.useState<Region[]>([]);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [commodities, setCommodities] = React.useState<Commodity[]>([]);
  const [excludeYears, setExcludeYears] = React.useState<boolean>(false);

  const toggleChecked = (reportChecked: ReportChecked) => {
    // if (checked.length >= 5) {
    //   return toast.error("Max 5 varieties can be selected at a time.");
    // }
    setChecked(prevChecked => {
      if (
        prevChecked
          .map(({ typeVarietyId, varietyId }: ReportChecked) => typeVarietyId)
          .indexOf(reportChecked.typeVarietyId) === -1
      ) {
        return [
          ...prevChecked,
          {
            typeVarietyId: reportChecked.typeVarietyId,
            varietyId: reportChecked.varietyId,
            varietyName: reportChecked.varietyName,
            commodityName: reportChecked.commodityName,
            regionName: reportChecked.regionName,
          },
        ];
      }
      const newChecked = remove(
        prevChecked,
        (prevReportChecked: ReportChecked) =>
          prevReportChecked.typeVarietyId !== reportChecked.typeVarietyId
      );
      return [...newChecked];
    });
  };

  const checkAll = (reportCheckeds: ReportChecked[]) => {
    setChecked(prevChecked => {
      const objectIds = prevChecked.map(
        ({ typeVarietyId }: ReportChecked) => typeVarietyId
      );
      reportCheckeds.forEach((item: ReportChecked) => {
        if (objectIds.indexOf(item.typeVarietyId) === -1) {
          prevChecked.push(item);
        }
      });
      return [...prevChecked];
    });
  };

  const handleNavigateCompare = () => {
    let searchParams = `?varieties=${checked.map((reportChecked: ReportChecked) => reportChecked.varietyId).join(",")}`;
    if (!isEmpty(regions)) {
      searchParams += `&regions=${regions.map(region => region.id).join(",")}`;
    }
    if (!isEmpty(commodities)) {
      searchParams += `&commodities=${commodities.map(commodity => commodity.id).join(",")}`;
    }
    if (startDate) {
      searchParams += `&start_date=${startDate.format("YYYY-MM-DD")}`;
    }
    if (endDate) {
      searchParams += `&end_date=${endDate.format("YYYY-MM-DD")}`;
    }
    if (excludeYears) {
      searchParams += `&exclude_years=${excludeYears ? "true" : "false"}`;
    }
    navigate({
      pathname: "/reports/compare",
      search: searchParams,
    });
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`TypeVariety_${process.env.REACT_APP_ENV}`}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "1rem",
          mb: "1rem",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant={"h4"}>Reports</Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={checked.length < 1}
            variant={"contained"}
            onClick={handleNavigateCompare}
          >
            <AddIcon />
          </Button>
        </Box>
      </Box>
      <ReportsSearch
        regions={regions}
        setRegions={setRegions}
        commodities={commodities}
        setCommodities={setCommodities}
        excludeYears={excludeYears}
        setExcludeYears={setExcludeYears}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <ReportsList
        checked={checked}
        checkAll={checkAll}
        toggleChecked={toggleChecked}
      />
    </InstantSearch>
  );
}
