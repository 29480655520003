import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import React from "react";
import {
  Configure,
  useSearchBox,
  UseSearchBoxProps,
} from "react-instantsearch";
import { Commodity, Region } from "../../types";
import CommodityMultiSelect from "../CommodityMultiSelect";
import RegionMultiSelect from "../RegionMultiSelect";

const LOCAL_STORAGE_KEY = "localstorage-reports-searchquery-1";

interface Props extends UseSearchBoxProps {
  regions: Region[];
  setRegions: React.Dispatch<React.SetStateAction<Region[]>>;
  commodities: Commodity[];
  setCommodities: React.Dispatch<React.SetStateAction<Commodity[]>>;
  endDate: Dayjs | null;
  setEndDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  startDate: Dayjs | null;
  setStartDate: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  excludeYears: boolean;
  setExcludeYears: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ReportsSearch(props: Props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { query, refine } = useSearchBox(props);
  const {
    regions,
    commodities,
    excludeYears,
    endDate,
    startDate,
    setRegions,
    setCommodities,
    setExcludeYears,
    setEndDate,
    setStartDate,
  } = props;

  React.useEffect(() => {
    // Load query from local storage on component mount
    const storedQuery = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedQuery) {      
      refine(storedQuery);
    }
  }, [refine]);

  React.useEffect(() => {
    // Save query to local storage on query change
    if (query) {
      localStorage.setItem(LOCAL_STORAGE_KEY, query);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, [query]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Configure
        attributesToRetrieve={[
          "commodity",
          "commodityId",
          "region",
          "regionId",
          "variety",
        ]}
        getRankingInfo={true}
        analytics={false}
        filters={buildFilters({
          regions,
          commodities,
          excludeYears,
          endDate,
          startDate,
        })}
        distinct={true}
      />
      <Box sx={{ flex: 1 }}>
        <TextField
          id="input-with-icon-textfield"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          autoFocus={true}
          value={query}
          onChange={event => refine(event.target.value)}
          variant={"standard"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: "1rem",
        }}
      >
        <Box sx={{ flexShrink: 1 }}>
          <FormControlLabel
            control={
              <Switch
                checked={excludeYears}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setExcludeYears(event.target.checked);
                }}
              />
            }
            label="Exclude Years"
          />
        </Box>
        {isSmallScreen && (
          <Box>
            <DatePicker
              label={"Start Date"}
              sx={{ width: "100%" }}
              value={startDate}
              onChange={newValue => setStartDate(newValue)}
            />
          </Box>
        )}
        {isSmallScreen && (
          <Box>
            <DatePicker
              label={"End Date"}
              sx={{ width: "100%" }}
              value={endDate}
              onChange={newValue => setEndDate(newValue)}
            />
          </Box>
        )}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box>
            <CommodityMultiSelect
              setCommodities={setCommodities}
              label={"Commodities"}
              commodities={commodities}
            />
          </Box>
          {!isSmallScreen && (
            <Box>
              <DatePicker
                label={"Start Date"}
                sx={{ width: "100%" }}
                value={startDate}
                onChange={newValue => setStartDate(newValue)}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box>
            <RegionMultiSelect
              regions={regions}
              setRegions={setRegions}
              label={"Regions"}
            />
          </Box>
          {!isSmallScreen && (
            <Box>
              <DatePicker
                label={"End Date"}
                sx={{ width: "100%" }}
                value={endDate}
                onChange={newValue => setEndDate(newValue)}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

function buildFilters({
  regions,
  commodities,
  excludeYears,
  startDate,
  endDate,
}: {
  excludeYears: boolean;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  commodities: Commodity[];
  regions: Region[];
}) {
  let filters = [];
  if (excludeYears) {
    if (startDate) {
      filters.push(`wet_date_days >= ${startDate.dayOfYear()}`);
    }
    if (endDate) {
      filters.push(`wet_date_days <= ${endDate.dayOfYear()}`);
    }
  } else {
    if (startDate) {
      filters.push(`wet_date_unix >= ${startDate.unix()}`);
    }
    if (endDate) {
      filters.push(`wet_date_unix <= ${endDate.unix()}`);
    }
  }
  const facetFilters = buildFacetFilters({
    commodities,
    regions,
  }).join(" AND ");
  if (facetFilters.length > 0) {
    filters.push(facetFilters);
  }
  const result = filters.join(" AND ");
  return result;
}

function buildFacetFilters({
  commodities,
  regions,
}: {
  commodities: Commodity[];
  regions: Region[];
}) {
  let facetFilters = [];
  if (commodities.length !== 0) {
    const arr = commodities.map(
      (commodity: Commodity) => `commodity_id:${commodity.id}`
    );
    facetFilters.push(arr.join(" OR "));
  }
  if (regions.length !== 0) {
    const arr = regions.map((region: Region) => `region_id:${region.id}`);
    facetFilters.push(arr.join(" OR "));
  }
  return facetFilters;
}
