import { Dayjs } from "dayjs";
import { isEmpty, isNil, unionBy } from "lodash";
import React from "react";
import { useSearchParams } from "react-router-dom";

import FieldVarietyApi from "../api/FieldVariety.api";
import TrialVarietyApi from "../api/TrialVariety.api";
import {
  Area,
  Company,
  ColumnType,
  Commodity,
  FieldVariety,
  Region,
  TrialVariety,
  Variety,
} from "../types";

type Props = {
  varietyIds: string[];
  initialAreas?: Area[];
  initialCommodities?: Commodity[];
  initialGrowers?: Company[];  
  initialRegions?: Region[];
  initialShippers?: Company[];
  initialStartDate: Dayjs | null;
  initialEndDate: Dayjs | null;
  initialExcludeYears: boolean;
  initialCulturalPractice?: string;
  children: JSX.Element;
};

interface IReportTypeVarietiesContext {
  areas: Area[];
  setAreas: (areas: Area[]) => void;
  growers: Company[];
  setGrowers: (growers: Company[]) => void;
  shippers: Company[];
  setShippers: (shippers: Company[]) => void;
  varietyIds: string[];
  finishedLoading: boolean;
  varietyById: { [id: number]: Variety };
  combinedTypeVarieties: ColumnType[];
  excludeYears: boolean;
  setExcludeYears: (arg: boolean) => void; // React.Dispatch<React.SetStateAction<boolean>>;
  trialVarieties: TrialVariety[];
  fieldVarieties: FieldVariety[];
  commodities: Commodity[];
  setCommodities: (commodities: Commodity[]) => void; // React.Dispatch<React.SetStateAction<Commodity[]>>;
  regions: Region[];
  setRegions: (regions: Region[]) => void; // React.Dispatch<React.SetStateAction<Region[]>>;
  startDate: Dayjs | null;
  setStartDate: (startDate: Dayjs | null) => void; // React.Dispatch<React.SetStateAction<Dayjs | null>>;
  endDate: Dayjs | null;
  setEndDate: (endDate: Dayjs | null) => void; // React.Dispatch<React.SetStateAction<Dayjs | null>>;
  culturalPractice?: string;
  setCulturalPractice: (arg?: string) => void;
}

const ReportTypeVarietiesContext = React.createContext<
  Partial<IReportTypeVarietiesContext>
>({});

export function ReportTypeVarietiesProvider({
  varietyIds,
  initialAreas,
  initialCommodities,
  initialGrowers,
  initialRegions,
  initialShippers,
  initialStartDate,
  initialEndDate,
  initialExcludeYears,
  initialCulturalPractice,
  children,
}: Props) {
  const [currentQueryParameters, setSearchParams] = useSearchParams();

  const [commodities, setCommodities] = React.useState<Commodity[]>(
    initialCommodities || []
  );

  const [shippers, setShippers] = React.useState<Company[]>(initialShippers || [])
  const [growers, setGrowers] = React.useState<Company[]>(initialGrowers || [])
  const [areas, setAreas] = React.useState<Area[]>(initialAreas || [])
  const [culturalPractice, setCulturalPractice] = React.useState<string | undefined>(initialCulturalPractice)

  const [regions, setRegions] = React.useState<Region[]>(initialRegions || []);
  const [startDate, setStartDate] = React.useState<Dayjs | null>(
    initialStartDate
  );
  const [endDate, setEndDate] = React.useState<Dayjs | null>(initialEndDate);
  const [excludeYears, setExcludeYears] =
    React.useState<boolean>(initialExcludeYears);

  const [fieldVarieties, setFieldVarieties] = React.useState<FieldVariety[]>(
    []
  );
  const [fieldVarietyPage, setFieldVarietyPage] = React.useState<number>(1);

  const [trialVarieties, setTrialVarieties] = React.useState<TrialVariety[]>(
    []
  );
  const [trialVarietyPage, setTrialVarietyPage] = React.useState<number>(1);

  const [trialVarietyFinished, setTrialVarietyFinished] =
    React.useState<boolean>(false);
  const [fieldVarietyFinished, setFieldVarietyFinished] =
    React.useState<boolean>(false);

  const { data: fieldVarietiesData, isFetched: fieldVarietyDataIsFetched } =
    FieldVarietyApi.useListByVarietyIds({      
      areaIds: areas.map(area => area.id),
      commodityIds: commodities.map(commodity => commodity.id),      
      culturalPractice,
      endDate,
      excludeYears,
      growerIds: growers.map(grower => grower.id),
      regionIds: regions.map(region => region.id),
      page: fieldVarietyPage,
      shipperIds: shippers.map(shipper => shipper.id),
      startDate,   
      varietyIds: varietyIds,
    });

  React.useEffect(() => {
    if (fieldVarietiesData && fieldVarietyDataIsFetched) {
      setFieldVarieties(fieldVarieties =>
        unionBy(fieldVarietiesData.data, fieldVarieties, "id")
      );
      setFieldVarietyPage(fieldVarietiesData.meta.nextPage);
      if (
        fieldVarietiesData?.meta?.currentPage >=
        fieldVarietiesData?.meta?.totalPages
      ) {
        setFieldVarietyFinished(true);
      }
    }
  }, [
    areas,
    commodities,
    culturalPractice,
    endDate,
    excludeYears,
    growers,
    regions,
    startDate,
    shippers,
    fieldVarietiesData,
    fieldVarietyDataIsFetched,
  ]);

  const { data: trialVarietiesData, isFetched: trialVarietyDataIsFetched } =
    TrialVarietyApi.useListByVarietyIds({
      areaIds: areas.map(area => area.id),
      commodityIds: commodities.map(commodity => commodity.id),      
      culturalPractice,
      endDate,
      excludeYears,
      growerIds: growers.map(grower => grower.id),
      regionIds: regions.map(region => region.id),
      page: trialVarietyPage,
      shipperIds: shippers.map(shipper => shipper.id),
      startDate,        
      varietyIds: varietyIds,      
    });

  React.useEffect(() => {
    if (trialVarietiesData && trialVarietyDataIsFetched) {
      setTrialVarieties(trialVarieties =>
        unionBy(trialVarietiesData.data, trialVarieties, "id")
      );
      setTrialVarietyPage(trialVarietiesData.meta.nextPage);
      if (
        trialVarietiesData?.meta?.currentPage >=
        trialVarietiesData?.meta?.totalPages
      ) {
        setTrialVarietyFinished(true);
      }
    }
  }, [
    areas,
    commodities,
    culturalPractice,
    endDate,
    excludeYears,
    growers,
    regions,
    startDate,
    shippers,
    trialVarietiesData,
    trialVarietyDataIsFetched,
  ]);

  const onFilterChange = () => {
    setFieldVarietyPage(1);
    setTrialVarietyPage(1);
    setFieldVarieties([]);
    setTrialVarieties([]);
    setTrialVarietyFinished(false);
    setFieldVarietyFinished(false);
  };

  const varietyById: { [id: number]: Variety } = {};
  let combinedTypeVarieties: ColumnType[] = [];
  trialVarieties?.forEach(trialVariety => {
    combinedTypeVarieties.push({
      evaluableType: "trial_variety",
      typeVariety: trialVariety,
    });
    if (trialVariety.variety) {
      varietyById[trialVariety.variety.id] = trialVariety.variety;
    }
  });
  fieldVarieties?.forEach(fieldVariety => {
    combinedTypeVarieties.push({
      evaluableType: "field_variety",
      typeVariety: fieldVariety,
    });
    if (fieldVariety.variety) {
      varietyById[fieldVariety.variety.id] = fieldVariety.variety;
    }
  });

  const finishedLoading = trialVarietyFinished && fieldVarietyFinished;
  return (
    <ReportTypeVarietiesContext.Provider
      value={{
        areas,
        setAreas: (areas: Area[]) => {
          if(isEmpty(areas)){
            currentQueryParameters.delete("areas")
            setSearchParams(currentQueryParameters)
          } else {
            currentQueryParameters.set("areas", areas.map(area => area.id).join(","))
            setSearchParams(currentQueryParameters);
          }
          onFilterChange()
          setAreas([...areas])
        },
        growers,
        setGrowers: (growers: Company[]) => {
          if(isEmpty(growers)){
            currentQueryParameters.delete("growers")
            setSearchParams(currentQueryParameters)
          } else {
            currentQueryParameters.set("growers", growers.map(grower => grower.id).join(","))
            setSearchParams(currentQueryParameters);
          }
          onFilterChange()
          setGrowers([...growers])
        },
        shippers,
        setShippers: (shippers: Company[]) => {
          if(isEmpty(shippers)){
            currentQueryParameters.delete("shippers")
            setSearchParams(currentQueryParameters)
          } else {
            currentQueryParameters.set("shippers", shippers.map(shipper => shipper.id).join(","))
            setSearchParams(currentQueryParameters);
          }
          onFilterChange()
          setShippers([...shippers])
        },
        varietyIds,
        finishedLoading,
        varietyById,
        combinedTypeVarieties,
        commodities,
        setCommodities: (commodities: Commodity[]) => {
          if (isEmpty(commodities)) {
            currentQueryParameters.delete("commodities");
            setSearchParams(currentQueryParameters);
          } else {
            currentQueryParameters.set(
              "commodities",
              commodities.map(commodity => commodity.id).join(",")
            );
            setSearchParams(currentQueryParameters);
          }
          onFilterChange();
          setCommodities([...commodities]);
        },
        excludeYears: initialExcludeYears,
        setExcludeYears: (arg: boolean) => {
          if (isNil(excludeYears)) {
            currentQueryParameters.delete("exclude_years");
            setSearchParams(currentQueryParameters);
          } else {
            currentQueryParameters.set("exclude_years", arg.toString());
            setSearchParams(currentQueryParameters);
          }
          onFilterChange();
          setExcludeYears(arg);
        },        
        culturalPractice: initialCulturalPractice,
        setCulturalPractice: (arg?: string) => {
          if(isNil(arg)){
            currentQueryParameters.delete("cultural_practice");
            setSearchParams(currentQueryParameters);
          } else {
            currentQueryParameters.set("cultural_practice", arg.toString());
            setSearchParams(currentQueryParameters);
          }
          onFilterChange();
          setCulturalPractice(arg);
        },
        regions,
        setRegions: (regions: Region[]) => {
          if (isEmpty(regions)) {
            currentQueryParameters.delete("regions");
            setSearchParams(currentQueryParameters);
          } else {
            currentQueryParameters.set(
              "regions",
              regions.map(region => region.id).join(",")
            );
            setSearchParams(currentQueryParameters);
          }
          onFilterChange();
          setRegions([...regions]);
        },
        trialVarieties,
        fieldVarieties,
        startDate,
        setStartDate: (startDate: Dayjs | null) => {
          if (isNil(startDate)) {
            currentQueryParameters.delete("start_date");
            setSearchParams(currentQueryParameters);
          } else {
            currentQueryParameters.set(
              "start_date",
              startDate.format("YYYY-MM-DD")
            );
            setSearchParams(currentQueryParameters);
          }
          onFilterChange();
          setStartDate(startDate);
        },
        endDate,
        setEndDate: (endDate: Dayjs | null) => {
          if (isNil(endDate)) {
            currentQueryParameters.delete("end_date");
            setSearchParams(currentQueryParameters);
          } else {
            currentQueryParameters.set(
              "end_date",
              endDate.format("YYYY-MM-DD")
            );
            setSearchParams(currentQueryParameters);
          }
          onFilterChange();
          setEndDate(endDate);
        },
      }}
    >
      {children}
    </ReportTypeVarietiesContext.Provider>
  );
}

export function useReportTypeVarietiesContext() {
  return React.useContext(ReportTypeVarietiesContext);
}
